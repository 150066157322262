.c-btn{
  &--v1 {
    background-color: var(--color-violet);
    color: var(--color-shade-0);
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 54px;
    min-width: 186px;
    line-height: 1em;
    transition: 0.6s all;
    img, svg {
      margin-right: 7px;
      width: 22px;
      path, g {
        transition: 0.6s all;
        fill: var(--color-primary);
      }
    }
    &:hover {
      background-color: var(--color-primary);
      svg {
        path, g {
          fill: var(--color-shade-0);
        }
      }
    }
  }
}