/* -----------------------------------------------------------------------------
 * FONTS SIZES
 */


@mixin f-font-sizes {
  --font-size-xxs  : 10px;
  --font-size-xs   : 12px;
  --font-size-s    : 14px;
  --font-size-m    : 16px;
  --font-size-l    : 18px;
  --font-size-xl   : 22px;
  --font-size-xxl  : 26px;
  --font-size-xxxl : 28px;
  --font-size-h    : 30px;

  @include breakpoint(s) {
    --font-size-xl   : 24px;
    --font-size-xxl  : 32px;
    --font-size-xxxl : 40px;
    --font-size-h    : 48px;
  }
}


// Map

$f-font-sizes: (
  xxs  : var(--font-size-xxs),
  xs   : var(--font-size-xs),
  s    : var(--font-size-s),
  m    : var(--font-size-m),
  l    : var(--font-size-l),
  xl   : var(--font-size-xl),
  xxl  : var(--font-size-xxl),
  xxxl : var(--font-size-xxxl),
  h    : var(--font-size-h)
) !default;