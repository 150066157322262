/* -----------------------------------------------------------------------------
 * COLORS
 */


// Variables
// -----------------------------------------------------------------------------

// Toggling

$u-colors-enabled: true !default;

// Maps

$u-colors-maps: $f-colors !default;


// Output
// -----------------------------------------------------------------------------

@if ($u-colors-enabled) {
  @each $_color-name, $_color-value in $u-colors-maps {
    .u-color-#{$_color-name} {
      color: $_color-value !important;
    }
  }
}