/* -----------------------------------------------------------------------------
 * FONT FAMILIES
 */


@mixin f-font-families {
  --font-family-primary   : 'Mukta', sans-serif;
  --font-family-secondary : 'Open Sans', sans-serif;
}


// Map

$f-font-families: (
  'primary'   : var(--font-family-primary),
  'secondary' : var(--font-family-secondary)
) !default;