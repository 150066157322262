/* -----------------------------------------------------------------------------
 * FOUNDATIONS
 */


@import "foundations.spaces";
@import "foundations.font-families";
@import "foundations.font-sizes";
@import "foundations.line-heights";
@import "foundations.colors";
@import "foundations.shadows";
@import "foundations.wrappers";

// Always lastest
@import "foundations.custom";

:root {
  @include f-spaces;
  @include f-font-families;
  @include f-font-sizes;
  @include f-line-heights;
  @include f-colors;
  @include f-shadows;
  @include f-wrappers;
  @include f-custom;
}