.c-squares{
  $this:&;
  position: absolute;
  bottom: 100%;
  left: 73px;
  height: 240px;
  width: 240px;
  @include breakpoint(l down) {
    height: 160px;
    width: 160px;
  }
  @include breakpoint(m down) {
    left: 24px;
  }
  img, svg {
    transition: 0.6s all;
    @include breakpoint(l down) {
      width: 80px;
    }
  }
  &__square1 {
    position: absolute;
    top: 0;
    left: 0;
    &:hover {
      img, svg {
        transform: translateX(120px);
        @include breakpoint(l down) {
          transform: translateX(80px);
        }
      }
    }
  }
  &__square2 {
    position: absolute;
    top: 120px;
    left: 0;
    @include breakpoint(l down) {
      top: 80px;
    }
    g {
      path {
        transition: 0.6s all;
      }
    }
    &:hover {
      img, svg {
        transform: translateX(120px) translateY(-120px);
        @include breakpoint(l down) {
          transform: translateX(80px) translateY(-80px);
        }
      }
    }
  }
  &__square3 {
    position: absolute;
    top: 120px;
    left: 120px;
    @include breakpoint(l down) {
      top: 80px;
      left: 80px;
    }
    &:hover {
      img, svg {
        transform: translateY(-120px);
        @include breakpoint(l down) {
          transform: translateY(-80px);
        }
      }
    }
  }
}