.c-modal{
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 999;
  height: 100vh;
  overflow-y: auto;
  opacity: 0;
  pointer-events: none;
  transition: 0.6s all;
  background-color: rgba(0,0,0,.75);
  color: var(--color-text);
  display: flex;
  align-items: center;
  &.is-active {
    opacity: 1;
    pointer-events: all;
  }

  &__close{
    font-size: 45px;
    font-weight: 900;
    transition: .2s all;
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 32px;
    z-index: 99;
    color: var(--color-shade-0);
    &:hover{
      opacity: .7;
      transition: .2s all;
    }
  }
  &__container {
    padding: 80px;
    width: 60vw;
    margin: 0 auto;
    max-height: 80vh;
    overflow-y: auto;
    background-color: var(--color-shade-0);
    p {
      margin-bottom: 16px;
    }
  }
}