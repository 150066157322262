/* -----------------------------------------------------------------------------
 * LINE HEIGHTS
 */


@mixin f-line-heights {
  --line-height-xs  : 1;
  --line-height-s   : 1.2;
  --line-height-m   : 1.4;
  --line-height-l   : 1.6;
  --line-height-xl  : 1.8;
  --line-height-xxl : 2;
}


// Map

$f-line-heights: (
  xs  : var(--line-height-xs),
  s   : var(--line-height-s),
  m   : var(--line-height-m),
  l   : var(--line-height-l),
  xl  : var(--line-height-xl),
  xxl : var(--line-height-xxl),
) !default;