/* -----------------------------------------------------------------------------
 * COMPONENTS
 */


//@import "components.";
@import "components.slide";
@import "components.carousel";
@import "components.pagination";
@import "components.breadcrumbs";
@import "components.header";
@import "components.tabs";
@import "components.modal";
@import "components.btn";
@import "components.head";
@import "components.menu-right";
@import "components.scroll-down";
@import "components.title";
@import "components.description";
@import "components.box";
@import "components.links-image";
@import "components.footer";
@import "components.menu";
@import "components.lines";
@import "components.contact";
@import "components.about";
@import "components.squares";
