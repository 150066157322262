.c-description {
  &--v1 {
    font-size: 20px;
    letter-spacing: 0.2px;
    color: var(--color-text);
    line-height: 1.2em;
    font-weight: 500;
    @include breakpoint(m down) {
      font-size: 18px;
    }
    p {
      margin-bottom: 24px;
    }
  }

}