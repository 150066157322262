/* -----------------------------------------------------------------------------
 * BG COLORS
 */


// Variables
// -----------------------------------------------------------------------------

// Toggling

$u-bg-colors-enabled: true !default;

// Maps

$u-bg-colors-maps: $f-colors !default;


// Output
// -----------------------------------------------------------------------------

@if ($u-bg-colors-enabled) {
  @each $_bg-color-name, $_bg-color-value in $u-bg-colors-maps {
    .u-bg-color-#{$_bg-color-name} {
      background-color: $_bg-color-value !important;
    }
  }
}