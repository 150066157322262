/* -----------------------------------------------------------------------------
 * WRAPPERS
 */


@mixin f-wrappers {
  --wrapper-xs  : 300px;
  --wrapper-s   : 600px;
  --wrapper-m   : 800px;
  --wrapper-l   : 1200px;
  --wrapper-xl  : 1326px;
  --wrapper-xxl : 1690px;
}


// Map

$f-wrappers: (
  xs  : var(--wrapper-xs),
  s   : var(--wrapper-s),
  m   : var(--wrapper-m),
  l   : var(--wrapper-l),
  xl  : var(--wrapper-xl),
  xxl : var(--wrapper-xxl)
) !default;
