/* -----------------------------------------------------------------------------
 * VENDOR
 */


//@import "vendor.";

@import "node_modules/slick-carousel/slick/slick.scss";
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~node_modules/aos/dist/aos.css';
@include breakpoint(m down) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

html:not(.no-js) [data-aos=zoom-out] {
  -webkit-transform: scale(1.6);
  transform: scale(1.6);
}