.u-relative {position: relative}
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
.mid-wrapper--xl {
  max-width: calc(var(--wrapper-xl)/2 - var(--space-gutter-wrapper));
}
.third-wrapper--xl {
  max-width: calc(var(--wrapper-xl)/4 - var(--space-gutter-wrapper));
}
.u-overflow-hidden {
  overflow: hidden;
}
.c-stamp-1 {
  position: absolute;
  right: 210px;
  bottom: -99px;
  @include breakpoint(l down) {
    right: 60px;
    width: 150px;
    bottom: -74px;
  }
}

.c-stamp-2 {
  position: absolute;
  right: 210px;
  bottom: 15px;
  @include breakpoint(l down) {
    right: 40px;
    width: 150px;
    bottom: 39px;
  }
}
.c-stamp-3 {
  position: absolute;
  left: -72px;
  top: -100px;
  z-index: 1;
  transform: rotate(
      33deg
  );
  @include breakpoint(xl down) {
    left: 32px;
    width: 150px;
    top: -75px;
  }
}
.c-stamp-4 {
  transform: rotate(
      33deg
  );
}
.u-flex-shrink-0 {
  flex-shrink: 0;
}
.u-no-wrap {
  position: relative;
  z-index: 1;
  @include breakpoint(m) {
    white-space: nowrap;
  }
}