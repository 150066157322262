.c-sliderCarousel {

    &__item {
        padding: 20px;
    }

    .slick-dots {
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 40px;
        width: 100%;
        text-align: center;

        li {
            display: inline-block;
            opacity: .5;
            
            &:not(:last-of-type) {
                margin-right: 10px;
            }

            &.slick-active {
                opacity: 1;
            }

            button {
                text-indent: -100000px;
                width: 10px;
                height: 10px;
                background: var(--color-primary);
                border-radius: 50px;
            }
        }
    }

    .slick-arrow {
        position: absolute;
        color: var(--color-primary);
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        background: none;
        border: none;
        font-size: 27px;

        &.slick-next {
            right: 20px;
        }

        &.slick-prev {
            left: 20px;
        }
    }

}