.c-about{
  $this:&;
  &__team {
    padding-bottom: 160px;
    @include breakpoint(m xl) {
      padding-left: 16px;
    }
    @include breakpoint(l down) {
      padding-bottom: 85px;
    }
    .slick-list {
      margin-right: -100vw;
      padding-right: 100vw;
      margin-left: -200px;
      padding-left: 200px;
    }
    .slick-slide {
      opacity: 0;
      transition: 0.6s all;
    }
    .slick-slide.slick-current,
    .slick-current ~.slick-slide
    {
      opacity: 1;
    }
    .slick-arrow {
      position: absolute;
      left: 0;
      width: 82px;
      height: 82px;
      display: flex;
      bottom: 0;
      justify-content: center;
      align-items: center;
      transition: 0.6s all;
      border: 3px solid var(--color-text);
      svg {
        g, path {
          transition: 0.6s all;
        }
      }
      @include breakpoint(l down) {
        width: 60px;
        height: 60px;
      }
      &:hover {
        background-color: var(--color-primary);
        svg {
          g, path {
            fill: var(--color-text);
          }
        }
      }
    }
    .slick-current {
      z-index: 10;
      position: relative;
    }
    .slick-next {
      left: 100px;
      @include breakpoint(l down) {
        left: 80px;
      }
      img, svg {
        transform: rotate(180deg);
      }
    }
    .slick-slide {
      &:not(.slick-current) {
        #{$this}__team-content {
          background-color: rgba(128, 147, 220, 0.74);
        }
        #{$this}__team-name {
          opacity: 0;
        }
      }
    }
  }
  &__team-item {
    position: relative;
  }
  &__team-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.6s all;
    display: flex;
    align-items: flex-end;
  }
  &__team-name {
    font-size: 70px;
    font-weight: bold;
    letter-spacing: 0.7px;
    color: var(--color-primary);
    padding-bottom: 107px;
    width: fit-content;
    white-space: nowrap;
    position: relative;
    left: -200px;
     @include breakpoint(xxl down) {
       left: 16px;
     }
    @include breakpoint(m l) {
      width: auto;
      white-space: inherit;
      font-size: 50px;
    }
    @include breakpoint(m down) {
      font-size: 30px;
    }
  }
}