/* -----------------------------------------------------------------------------
 * PX TO EM
 */


@function px-to-em($_value, $_baseline: $s-config-font-size-base) {
  @if unit($_value) == 'em' {
    @return $_value;
  }
  @else {
     @if unitless($_value){
      $_value: $_value + 0px;
    }
    @return $_value / $_baseline * 1em;
  }
}