/* -----------------------------------------------------------------------------
 * CONFIG
 */


/* -----------------------------------------------------------------------------
 * Font size base for functions, etc. (not document font size)
 */

$s-config-font-size-base: 16px !default;


/* -----------------------------------------------------------------------------
 * Paths
 */

$s-config-path-fonts: '../fonts/';
$s-config-path-images: '../images/';


/* -----------------------------------------------------------------------------
 * Special chars
 */

$s-config-breakpoint-separator: \@ !default;
$s-config-breakpoint-down-separator: \# !default;
$s-config-aspect-ratio-separator: \: !default;


/* -----------------------------------------------------------------------------
 * Mediaqueries
 */

// Media type

$s-mq-media-type: 'screen' !default;

// Main breakpoint variables

$s-mq-breakpoint-s   : 600px !default;
$s-mq-breakpoint-m   : 900px !default;
$s-mq-breakpoint-l   : 1200px !default;
$s-mq-breakpoint-xl  : 1500px !default;
$s-mq-breakpoint-xxl : 1800px !default;

// Breakpoints map

$s-mq-breakpoints: (
  s   : $s-mq-breakpoint-s,
  m   : $s-mq-breakpoint-m,
  l   : $s-mq-breakpoint-l,
  xl  : $s-mq-breakpoint-xl,
  xxl : $s-mq-breakpoint-xxl
) !default;