.c-links-image{
  $this:&;
  font-size: 78px;
  letter-spacing: 0.78px;
  line-height: 1.2em;
  padding-top: 130px;
  padding-bottom: 155px;
  position: relative;
  font-weight: 600;
  color: var(--color-text);
  @include breakpoint(l xl) {
    font-size: 60px;
  }
  @include breakpoint(m l) {
    font-size: 40px;
    padding-bottom: 100px;
  }
  @include breakpoint(m down) {
    font-size: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  #{$this}__lines {
    position: absolute;
    width: 1278px;
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    top: 0;
    height: 0;
    z-index: -1;
    transition: 2s all;
    span {
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      background-color: rgba(128, 147, 220, 0.2);
      &:first-of-type {
        left: 0;
      }
      &:nth-of-type(2) {
        left: 25%;
      }
      &:nth-of-type(3) {
        left: 50%;
      }
      &:nth-of-type(4) {
        left: 75%;
      }
      &:nth-of-type(5) {
        left: calc(100% - 1px);
      }
    }
  }
  &__hover {
    width: 1278px;
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: -1;
    top: 0;
    opacity: 0;
    transition: 2s all;
  }
  &__link {
    display: block;
    margin: 50px 0;
    position: relative;
    transition: 0.6s all;
    @include breakpoint(l down) {
      margin: 30px 0;
    }
    &:nth-of-type(odd) {
      #{$this}__first {
        position: absolute;
        left: 1px;
        width: calc(25% - 1px);
        height: auto;
        top: -145px;
      }
      #{$this}__second {
        position: absolute;
        left: calc(50% + 1px);
        width: calc(25% - 1px);
        height: auto;
        top: -80px;
      }
    }
    &:nth-of-type(even) {
      #{$this}__first {
        position: absolute;
        left: calc(25% + 1px);
        width: 25%;
        height: auto;
        top: -145px;
      }
      #{$this}__second {
        position: absolute;
        left: calc(75% + 1px);
        width: 25%;
        height: auto;
        top: -80px;
      }
    }
    &:hover {
      color: var(--color-primary);
      ~ #{$this}__lines {
        opacity: 1;
        height: 100%;
      }
      #{$this}__hover {
        opacity: 1;
        height: 100%;
      }
    }
  }
}