.c-title {
  &--v1 {
    font-size: 65px;
    color: var(--color-text);
    letter-spacing: 0.65px;
    line-height: 1.1em;
    font-weight: bold;
    @include breakpoint(m xl) {
      font-size: 50px;
    }
    @include breakpoint(m down) {
      font-size: 30px;
    }
  }
  &--v2 {
    font-size: 150px;
    letter-spacing: 1.5px;
    position: relative;
    z-index: 1;
    font-weight: 300;
    color: var(--color-text);
    @include breakpoint(m xl) {
      font-size: 100px;
    }
    @include breakpoint(s m) {
      font-size: 80px;
    }
    @include breakpoint(s down) {
      font-size: 60px;
    }
  }
  &--v3 {
    font-size: 350px;
    font-weight: bold;
    letter-spacing: 3.5px;
    color: var(--color-primary);
    position: relative;
    right: -16px;
    @include breakpoint(l xxl) {
      font-size: 250px;
    }
    @include breakpoint(m l) {
      font-size: 150px;
    }
    @include breakpoint(s m) {
      font-size: 100px;
    }
    @include breakpoint(s down) {
      font-size: 80px;
    }
  }
  &--v4 {
    font-size: 40px;
    color: var(--color-text);
    letter-spacing: 0.4px;
    line-height: 1.1em;
    font-weight: bold;
    @include breakpoint(m down) {
      font-size: 30px;
    }
  }
  &--v5 {
    font-size: 50px;
    letter-spacing: 0.5px;
    color: var(--color-text);
    line-height: 1.1em;
    @include breakpoint(m down) {
      font-size: 40px;
    }
  }
  &--v6 {
    font-size: 100px;
    color: var(--color-text);
    letter-spacing: 0.65px;
    line-height: 1em;
    font-weight: bold;
    @include breakpoint(l xl) {
      font-size: 80px;
    }
    @include breakpoint(m l) {
      font-size: 60px;
    }
    @include breakpoint(m down) {
      font-size: 40px;
    }
  }
  &--v7 {
    font-size: 33px;
    font-weight: 300;
    letter-spacing: 0.33px;
    line-height: 1em;
    color: var(--color-primary);
  }
}

