.c-scroll-down {
  position: absolute;
  right: -45px;
  top: 0;
  text-align: center;
  text-transform: uppercase;
  @include breakpoint(xxl) {
    right: 75px;
  }
  span {
    margin-top: 90px;
    transform: rotateZ(180deg);
    font-size: 20px;
    letter-spacing: 1.6px;
    color: var(--color-primary);
    display: block;
    text-align: center;
    transition: 0.6s all;
    @include breakpoint(xxl down) {
      margin-top: 24px;
    }
  }
  img, svg {
    position: relative;
    transition: 0.6s all;
  }
  &:hover {
    span {
      transform: rotateZ(
          90deg
      );
    }
    img, svg {
      transform: translateY(20px);
    }
  }
  &--vertical {
    @include breakpoint(xxl) {
      top: 20vh;
    }
    span {
      transform: rotateZ(
          -90deg
      );
      @include breakpoint(xxl down) {
        margin-top: 80px;
      }
    }
  }
  @include breakpoint(m down) {
    display: none;
  }
}