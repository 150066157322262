.c-lines {
    position: fixed;
    width: 1278px;
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    transition: 2s all;
  height: 100%;
  z-index: -1;
    span {
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      background-color: rgba(128, 147, 220, 0.2);
      &:first-of-type {
        left: 0;
      }
      &:nth-of-type(2) {
        left: 25%;
      }
      &:nth-of-type(3) {
        left: 50%;
      }
      &:nth-of-type(4) {
        left: 75%;
      }
      &:nth-of-type(5) {
        left: calc(100% - 1px);
      }
    }
}