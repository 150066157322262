.c-slide {

    &__item {
        position: relative;
    }
    &__content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.2);
    }
    &__title {
      color: var(--color-shade-0);
      font-size: 30px;
      @include breakpoint(m) {
        font-size: 80px;
      }
      line-height: 1.2em;
    }
    .slick-dots {
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 40px;
        width: 100%;
        text-align: center;

        li {
            display: inline-block;
            opacity: .5;

            &:not(:last-of-type) {
                margin-right: 10px;
            }

            &.slick-active {
                opacity: 1;
            }

            button {
                text-indent: -100000px;
                width: 10px;
                height: 10px;
                background: var(--color-primary);
                border-radius: 50px;
            }
        }
    }

    .slick-arrow {
        position: absolute;
        color: var(--color-shade-0);
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        background: none;
        border: none;
        font-size: 20px;
        @include breakpoint(m) {
          font-size: 27px;
        }
        &.slick-next {
          right: 4px;
          @include breakpoint(m) {
            right: 20px;
          }
        }

        &.slick-prev {
          left: 4px;
          @include breakpoint(m) {
            left: 20px;
          }
        }
    }

}
