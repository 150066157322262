/* -----------------------------------------------------------------------------
 * FONTS
 */


// @font-face {
//   font-family: 'fontname';
//   src: url('#{$s-config-path-fonts}fontfile.woff2') format('woff2'),
//        url('#{$s-config-path-fonts}fontfile.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  src: url(#{$s-config-path-fonts}fa-brands-400.eot);
  src: url(#{$s-config-path-fonts}fa-brands-400.eot?#iefix) format("embedded-opentype"),url(#{$s-config-path-fonts}fa-brands-400.woff2) format("woff2"),url(#{$s-config-path-fonts}fa-brands-400.woff) format("woff"),url(#{$s-config-path-fonts}fa-brands-400.ttf) format("truetype"),url(#{$s-config-path-fonts}fa-brands-400.svg#fontawesome) format("svg")
}

.fab {
  font-family: "Font Awesome 5 Brands"
}


.far {
  font-weight: 400
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  src: url(#{$s-config-path-fonts}fa-solid-900.eot);
  src: url(#{$s-config-path-fonts}fa-solid-900.eot?#iefix) format("embedded-opentype"),url(#{$s-config-path-fonts}fa-solid-900.woff2) format("woff2"),url(#{$s-config-path-fonts}fa-solid-900.woff) format("woff"),url(#{$s-config-path-fonts}fa-solid-900.ttf) format("truetype"),url(#{$s-config-path-fonts}fa-solid-900.svg#fontawesome) format("svg")
}

.fa,.fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900
}


@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 300;
  src: url(#{$s-config-path-fonts}fa-light-300.eot);
  src: url(#{$s-config-path-fonts}fa-light-300.eot?#iefix) format("embedded-opentype"),url(#{$s-config-path-fonts}fa-light-300.woff2) format("woff2"),url(#{$s-config-path-fonts}fa-light-300.woff) format("woff"),url(#{$s-config-path-fonts}fa-light-300.ttf) format("truetype"),url(#{$s-config-path-fonts}fa-light-300.svg#fontawesome) format("svg")
}

.fal {
  font-weight: 300
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 400;
  src: url(#{$s-config-path-fonts}fa-regular-400.eot);
  src: url(#{$s-config-path-fonts}fa-regular-400.eot?#iefix) format("embedded-opentype"),url(#{$s-config-path-fonts}fa-regular-400.woff2) format("woff2"),url(#{$s-config-path-fonts}fa-regular-400.woff) format("woff"),url(#{$s-config-path-fonts}fa-regular-400.ttf) format("truetype"),url(#{$s-config-path-fonts}fa-regular-400.svg#fontawesome) format("svg")
}

.fal,.far {
  font-family: "Font Awesome 5 Pro"
}
.fa,
.fab,
.fal,
.far,
.fas {
  font-style: normal;
}