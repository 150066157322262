.c-head {
  position: relative;
  &__logo {
    position: absolute;
    right: 300px;
    z-index: 1;
    top: -100px;
    @include breakpoint(l down) {
      top: -60px;
      right: 60px;
      width: 150px;
    }
  }
  &__squares {
    position: absolute;
    bottom: -119px;
    z-index: 2;
    right: 300px;
    height: 240px;
    width: 360px;
    @include breakpoint(l down) {
      width: 240px;
      height: 160px;
      bottom: -79px;
    }
    @include breakpoint(m l) {
      right: 150px;
    }
    @include breakpoint(m down) {
      right: 0;
    }
    img {
      transition: 0.6s all !important;
      @include breakpoint(l down) {
        width: 80px;
      }
    }
  }
  &__square1 {
    position: absolute;
    top: 0;
    left: 120px;
    @include breakpoint(l down) {
      left: 80px;
    }
    &:hover {
      img {
        transform: translateX(-120px);
        @include breakpoint(l down) {
          transform: translateX(-80px);
        }
      }
    }
  }
  &__square2 {
    position: absolute;
    top: 0;
    left: 240px;
    @include breakpoint(l down) {
      left: 160px;
    }
    &:hover {
      img {
        transform: translateY(120px);
        @include breakpoint(l down) {
          transform: translateY(80px);
        }
      }
    }
  }
  &__square3 {
    position: absolute;
    top: 120px;
    left: 0;
    @include breakpoint(l down) {
      top: 80px;
    }
    &:hover {
      img {
        transform: translateY(-120px);
        @include breakpoint(l down) {
          transform: translateY(-80px);
        }
      }
    }
  }
  &__square4 {
    position: absolute;
    top: 120px;
    left: 120px;
    @include breakpoint(l down) {
      top: 80px;
      left: 80px;
    }
    &:hover {
      img {
        transform: translateX(120px);
        @include breakpoint(l down) {
          transform: translateX(80px);
        }
      }

    }
  }
  &__content-title {
    font-size: 90px;
    letter-spacing: 0.9px;
    color: var(--color-primary);
    @include breakpoint(l xl) {
      font-size: 60px;
    }
    @include breakpoint(s l) {
      font-size: 40px;
    }
    @include breakpoint(s down) {
      font-size: 30px;
    }
    span {
      display: block;
      @include breakpoint(l) {
        &:nth-of-type(even) {
          text-align: right;
        }
        overflow: hidden; /* Ensures the content is not revealed until the animation */
        white-space: nowrap;
        margin: 0 auto; /* Gives that scrolling effect as the typing happens */
        animation: typing 1.5s steps(40, end),
        blink-caret .75s step-end infinite;
      }
    }
  }
  &__content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .u-images {
    min-height: 350px;
  }
}
