.c-box{
  $this:&;
  &__item {
    position: relative;
    overflow: hidden;
    &:first-of-type {
      #{$this}__item-content {
        background-color: rgba(128, 147, 220, 0.75);
      }
    }
    &:nth-of-type(2) {
      #{$this}__item-content {
        background-color: rgba(255, 144, 21, 0.75);
      }
    }
    &:last-of-type {
      #{$this}__item-content {
        background-color: rgba(0, 89, 78, 0.75);
      }
    }
    picture {
      overflow: hidden;

      img {
        transition: transform 1.2s, filter 1s ease-in-out;
      }
    }
    &:hover {
      picture {
          img{
            filter: brightness(110%);
            transform: scale(1.2);
          }
      }
      #{$this}__item-content {
        background-color: transparent !important;
      }
    }
    @include breakpoint(m down) {
      .u-images {
        padding-top: 100%;
      }
    }
  }
  &__item-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 140px 110px;
    color: var(--color-shade-0);
    transition: 1.2s all;
    @include breakpoint(xl down) {
      padding: 40px 40px;
    }
  }
  &__item-number {
    font-size: 60px;
    letter-spacing: 0.6px;
    @include breakpoint(l down) {
      font-size: 50px;
    }
  }
  &__item-title {
    font-size: 50px;
    letter-spacing: 0.5px;
    @include breakpoint(l down) {
      font-size: 40px;
    }
  }
  &__item-description {
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 1.25;
    @include breakpoint(m xl) {
     font-size: 15px;
    }
  }
}