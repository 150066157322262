/* -----------------------------------------------------------------------------
 * MARGINS
 */


// Variables
// -----------------------------------------------------------------------------

// Toggling

$u-margin-enabled: true !default;

// Spaces

$u-margin-spaces: $f-spaces !default;
$u-margin-classes: (
  "mrt": (
    "margin-top"
  ),
  "mrr": (
    "margin-right"
  ),
  "mrb": (
    "margin-bottom"
  ),
  "mrl": (
    "margin-left"
  ),
  "mrv": (
    "margin-top",
    "margin-bottom"
  ),
  "mrh": (
    "margin-left",
    "margin-right"
  ),
  "mr" : (
    "margin-top",
    "margin-right",
    "margin-bottom",
    "margin-left"
  )
) !default;

// Breakpoints

$u-margin-breakpoints-enabled: true !default;
$u-margin-breakpoints: $s-mq-breakpoints !default;


// Ouput
// -----------------------------------------------------------------------------

@if $u-margin-enabled {
  @each $_space-name, $_space-value in $u-margin-spaces {
    @each $_class-name, $_class-properties in $u-margin-classes {
      .u-#{$_class-name}-#{$_space-name} {
        @each $_property-name in $_class-properties {
          #{$_property-name}: $_space-value !important;
        }
      }
    }
  }
}


// Breakpoints ouput
// -----------------------------------------------------------------------------

@if $u-margin-enabled and $u-margin-breakpoints-enabled {
  @each $_space-name, $_space-value in $u-margin-spaces {
    @each $_bp-name, $_bp-value in $u-margin-breakpoints {
      @each $_class-name, $_class-properties in $u-margin-classes {
        .u-#{$_class-name}-#{$_space-name}#{$s-config-breakpoint-separator}#{$_bp-name} {
          @include breakpoint($_bp-value) {
            @each $_property-name in $_class-properties {
              #{$_property-name}: $_space-value !important;
            }
          }
        }

        .u-#{$_class-name}-#{$_space-name}#{$s-config-breakpoint-down-separator}#{$_bp-name} {
          @include breakpoint($_bp-value down) {
            @each $_property-name in $_class-properties {
              #{$_property-name}: $_space-value !important;
            }
          }
        }

      }
    }
  }
}