/* -----------------------------------------------------------------------------
 * SPACES
 */


@mixin f-spaces {
  --space-none : 0px;
  --space-xs   : 8px;
  --space-s    : 16px;
  --space-m    : 24px;
  --space-l    : 32px;
  --space-xl   : 40px;
  --space-xxl  : 48px;
  --space-xxxl : 56px;
  --space-h    : 64px;
  --space-xh   : 72px;
  --space-xxh  : 80px;
  --space-xxxh : 88px;
  --space-2xxxl : 112px;
  --space-2h   : 128px;
  --space-2xh   : 144px;
  --space-2xxh  : 160px;
  --space-2xxxh  : 176px;
  --space-4h  : 256px;
  --space-4xxh : 320px;
}


// Map

$f-spaces: (
  'none' : var(--space-none),
  'xs'   : var(--space-xs),
  's'    : var(--space-s),
  'm'    : var(--space-m),
  'l'    : var(--space-l),
  'xl'   : var(--space-xl),
  'xxl'  : var(--space-xxl),
  'xxxl' : var(--space-xxxl),
  'h'    : var(--space-h),
  'xh'   : var(--space-xh),
  'xxh'  : var(--space-xxh),
  'xxxh' : var(--space-xxxh),
  '2xxxl' : var(--space-2xxxl),
  '2h' : var(--space-2h),
  '2xh' : var(--space-2xh),
  '2xxh' : var(--space-2xxh),
  '2xxxh' : var(--space-2xxxh),
  '4xxh' : var(--space-4xxh),
  '4h' : var(--space-4h)
) !default;