.c-footer {
  $this: &;
  color: var(--color-shade-0);
  position: relative;
  z-index: 2;
  &__title {
    font-size: 57px;
    letter-spacing: 0.65px;
    line-height: 1.1em;
    font-weight: bold;
    @include breakpoint(xl down) {
      font-size: 30px;
    }
  }
  &__social {
    svg {
      g, path {
        transition: 0.6s all;
      }
      &:hover {
        g, path {
          fill: var(--color-secondary);
        }
      }
    }
  }
  &__social-title {
    font-size: 25px;
    letter-spacing: 0.75px;
  }
  ul {
    display: flex;
    align-items: center;
    li {
      margin-right: 13px;
    }
  }
  &__contact-title {
    font-size: 40px;
    letter-spacing: 1.2px;
    line-height: 0.95em;
    color: var(--color-primary);
    @include breakpoint(l down) {
      font-size: 30px;
    }
  }
  &__form-input {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--color-shade-0);
    font-size: 20px;
    letter-spacing: 0.6px;
    padding-bottom: 12px;
    color: var(--color-shade-0);
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: var(--color-shade-0);
    }
  }
  &__form-checkbox {
    font-size: 15px;
    letter-spacing: 0.45px;
    position: relative;
    display: inline-block;
    line-height: 1.4em;
    a {
      font-weight: bold;
    }
    input {
      display: none;
      &:checked ~ #{$this}__form-checkmark {
        background-color: var(--color-shade-0);
      }
    }
    span {
      display: inline-block;
      padding-left: 28px;
    }
  }
  &__form-checkmark {
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 2px solid;
    position: absolute;
    left: 0;
    top: 5px;
    padding-left: 0 !important;
  }
  &__address-title {
    color: var(--color-primary);
    font-size: 25px;
    letter-spacing: 0.75px;
  }
  &__address-value {
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 1.1em;
  }
  .c-title--v1 {
    @include breakpoint(l down) {
      font-size: 30px;
    }
  }
  &__squares {
    position: absolute;
    left: 32px;
    top: 0;
    height: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    @include breakpoint(l down) {
      display: none;
    }
  }
  &__square1,
  &__square2,
  &__square3,
  &__square4,
  &__square5 {
    flex-grow: 1;
    height: 20%;
    transition: 0.6s all;
    &:hover {
      transform: translateX(100%) !important;
    }
  }
}