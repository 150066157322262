.c-header {
  $this: &;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  padding: 38px 0 28px 0;
  //background-color: var(--color-shade-0);
  transition: 0.4s all;
  @include breakpoint(m down) {
    font-size: 12px;
    padding: 12px 0 12px 0;
  }
  &.stuck {
    background-color: var(--color-shade-0);
    padding: 16px 0;
    -webkit-box-shadow: 0px 1px 3px 0px rgba(255,146,21,1);
    -moz-box-shadow: 0px 1px 3px 0px rgba(255,146,21,1);
    box-shadow: 0px 1px 3px 0px rgba(255,146,21,1);
    #{$this} {
      &__logo {
        width: 75px;
      }
    }
  }
  &__logo {
    width: 130px;
    transition: .4s all;
    @include breakpoint(l down) {
      width: 75px;
    }
  }
  &__menu {
    @include breakpoint(m down) {
      opacity: 0;
      pointer-events: none;
      position: fixed;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      background-color: var(--color-primary);
      color: var(--color-shade-0);
      &.open {
        opacity: 1;
        pointer-events: all;
        #{$this} {

        }
      }
    }
  }
  &__menu-items {
    font-size: 25px;
    display: flex;
    justify-content: flex-end;
    @include breakpoint(l down) {
      font-size: 20px;
    }
    @include breakpoint(m down) {
      margin-top: 60px;
      flex-direction: column;
    }
    li {
      margin: 20px;
      transition: .4s all;
      @include breakpoint(m) {
        margin: 0 20px;
      }
      @include breakpoint(m down) {
        font-size: 35px;
      }
      &.is-active, &:hover {
        @include breakpoint(m) {
          border-bottom: 1px solid;
        }
      }
      &.is-active {
        font-weight: 600;
      }
    }
  }
  &__menu-mb {
    width: 72px;
    height: 31px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    @include breakpoint(l down) {
      width: 40px;
      height: 17px;
    }
    span {
      width: 37px;
      margin-left: 18px;
      display: block;
      height: 3px;
      background-color: var(--color-primary);
      transition: 0.4s all;
      @include breakpoint(l down) {
        width: 22px;
        margin-left: 10px;
      }
      &:first-of-type {
       width: 52px;
        margin-left: 0;
        @include breakpoint(l down) {
          width: 25px;
        }
      }
      &:last-of-type {
        width: 52px;
        margin-left: 20px;
        @include breakpoint(l down) {
          width: 25px;
          margin-left: 15px;
        }
      }
    }
      &:hover {
        span {
          &:first-of-type {
            width: 40px;
            transform: rotateZ(
                90deg
            );
            top: 16px;
            position: relative;
            left: 25px;
            @include breakpoint(l down) {
              top: 3px;
            }
          }
          &:last-of-type {
            display: none;
          }

          &:nth-of-type(2) {
            width: 40px;
            margin-left: 24px;
            margin-bottom: 13px;
          }
        }
      }
  }
}