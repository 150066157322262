/* -----------------------------------------------------------------------------
 * RESET
 */

 * {
  &,
  &::after,
  &::before {
    box-sizing: inherit;
  }
}

html {
  box-sizing: border-box;
  font-family: var(--font-family);
  font-size: var(--font-size);
  line-height: var(--line-height-xs);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin:  0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

dl, dd, ul, ol {
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  padding: 0;
  outline: none;
  border: 0;
  background: none;
  color: inherit;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  appearance: none;
  cursor: pointer;
}

picture {
  display: block;
}

img, svg {
  max-width: 100%;
  height: auto;
}

img {
  object-fit: cover;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

fieldset {
  min-width: 0;
  border: 0;
}