.c-menu-right {
  $this:&;
  top: 0;
  position: fixed;
  left: 42px;
  height: 100%;
  transform: rotate(
      180deg
  );
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.8px;
  writing-mode: vertical-rl;
  text-orientation: inherit;
  color: var(--color-primary);
  text-transform: uppercase;
  z-index: 2;
  @include breakpoint(xl down) {
    left: 8px
  }
  @include breakpoint(m down) {
    display: none;
  }
  &__list {
    display: flex;
    li {
      margin: 50px 0;
      a {
        white-space: nowrap;
        transition: 0.6s all;
        &:hover {
          color: var(--color-secondary);
        }
      }
    }
  }
  &--inside {
    position: absolute;
    color: var(--color-shade-0);
    overflow: hidden;
  }
  &__effect-bottom {
    opacity: 0;
    transition: all .6s;
    transition-delay: 1s;
    transform: translateY(-100px);
  }

}