.c-menu {
  $this: &;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: var(--color-primary);
  top: 0;
  left: 0;
  z-index: 10;
  color: var(--color-shade-0);
  transition: 1.5s all;
  .o-wrapper {
    min-height: 100%;
  }
  a {
    transition: 0.6s all;
    &:hover {
      color: var(--color-text);
    }
  }
  &:not(.is-active) {
    opacity: 0;
    pointer-events: none;
    transform: translateY(100vh);
    overflow-y: hidden;
  }
  &__contact-title {
    color: var(--color-text);
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.66px;
    @include breakpoint(m down) {
      font-size: 18px;
    }
  }
  &__contact-value {
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 1.6em;
    font-weight: 300;
    @include breakpoint(m down) {
      font-size: 16px;
    }
  }
  &__lines {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 2s all;
    z-index: -1;
    span {
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      background-color: rgba(255, 255, 255, 0.3);

      &:first-of-type {
        left: 0;
      }

      &:nth-of-type(2) {
        left: 25%;
      }

      &:nth-of-type(3) {
        left: 50%;
      }

      &:nth-of-type(4) {
        left: 75%;
      }

      &:nth-of-type(5) {
        left: calc(100% - 1px);
      }
    }
  }
  &__list {
    font-size: 40px;
    font-weight: 800;
    letter-spacing: 2.22px;
    line-height: 1.2em;
    text-transform: uppercase;
    position: relative;
    @include breakpoint(xxl) {
      width: calc(100% + 130px);
    }
    li {
      margin-bottom: 32px;
    }
    @include breakpoint(m xxl) {
      font-size: 50px;
    }
    @include breakpoint(m down) {
      font-size: 16px;
      li {
        margin-bottom: 16px;
      }
    }
    a {
      display: inline-block;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 3px;
        background-color: var(--color-text);
        width: 0;
        transition: 0.6s all;
      }
      &:hover {
        &:after {
          width: 100%;
        }
      }
    }
  }
  &__logo {
    position: absolute;
    top: 50px;
    left: 60px;
    display: inline-block;
    opacity: 0;
    transition: all .6s;
    transition-delay: 1.5s;
    transform: scale(1.4);
    @include breakpoint(m down) {
      left: 16px;
    }
  }
  &__close {
    width: 52px;
    height: 52px;
    display: inline-block;
    position: absolute;
    @include breakpoint(l down) {
      right: 32px;
      top: 32px;
      width: 40px;
      height: 40px;
    }
    right: 121px;
    top: 80px;
    border: 3px solid;
    transition: 0.6s all;
    z-index: 10;
    &:before, &:after {
      position: absolute;
      left: 22px;
      content: ' ';
      height: 33px;
      width: 2px;
      top: 6px;
      background-color: var(--color-shade-0);
      transition: 0.6s all;
      @include breakpoint(l down) {
        left: 16px;
        top: 1px;
      }
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
    &:hover {
      background-color: var(--color-shade-0);
      &:before, &:after {
        background-color: var(--color-text);
      }
    }
  }
  &__effect-left {
    opacity: 0;
    transition: all .6s;
    transition-delay: 1s;
    transform: translateX(-100px);
  }
  &__effect-right {
    opacity: 0;
    transition: all .6s;
    transition-delay: 1.5s;
    transform: translateX(100px);
  }
  &__stamp {
    margin-left: 30%;
    transform: rotate(
        -56deg
    ) scale(1.4);
    opacity: 0;
    transition: all .6s;
    transition-delay: 2.1s;
    path, g {
      fill: var(--color-shade-0);
    }
    @include breakpoint(l down) {
      opacity: 0 !important;
    }
  }
  &__social {
    ul {
      display: flex;
      li {
        margin-right: 12px;
        a {
          svg {
            path, g {
              transition: 0.6s all;
              fill: var(--color-shade-0);
            }
          }
          &:hover {
            svg {
              path, g {
                fill: var(--color-text);
              }
            }
          }
        }
      }
    }
  }
  &.is-active {
    #{$this} {
      &__effect-left,
      &__effect-right,
      {
        transform: none;
        opacity: 1;
        transition: all 1s;
        transition-delay: 1s;
      }
      &__effect-right {
        transform: none;
        opacity: 1;
        transition: all 1s;
        transition-delay: 1.5s;
      }
      &__logo {
        transform: none;
        opacity: 1;
        transition: all 1s;
        transition-delay: 1.5s;
      }
      &__stamp {
        transform:  rotate(
            -56deg
        );
        opacity: 1;
        transition: all 1s;
        transition-delay: 1.5s;
      }
    }
    .c-menu-right__effect-bottom {
      transform: none;
      opacity: 1;
      transition: all 1s;
      transition-delay: 1s;
    }
  }
}