.c-contact {
  $this: &;
  &__title {
    font-size: 40px;
    letter-spacing: 1.2px;
    line-height: 0.95em;
    color: var(--color-primary);
  }
  &__input {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--color-text);
    font-size: 20px;
    letter-spacing: 0.6px;
    padding-bottom: 12px;
    color: var(--color-text);
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: var(--color-text);
    }
  }
  &__checkbox {
    font-size: 20px;
    letter-spacing: 0.45px;
    position: relative;
    display: inline-block;
    line-height: 1.4em;
    a {
      font-weight: bold;
    }
    input {
      display: none;
      &:checked ~ #{$this}__checkmark {
        background-color: var(--color-text);
      }
    }
    span {
      display: inline-block;
      padding-left: 32px;
    }
  }
  &__checkmark {
    display: inline-block;
    width: 22px;
    height: 22px;
    border: 2px solid;
    position: absolute;
    left: 0;
    top: 4px;
    padding-left: 0 !important;
  }
  &__address-title {
    color: var(--color-primary);
    font-size: 30px;
    letter-spacing: 0.75px;
    font-weight: bold;
  }
  &__address-value {
    font-size: 30px;
    letter-spacing: 0.2px;
    line-height: 1.1em;
  }
}