
.c-tabs {
  $this:&;
  &__list {
    a {
      &.is-active {
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
  &__container {
    display: flex;
  }
 &__item {
   display: none;
   transition: .3s all;
   width: 100%;
   &.is-active {
     display: block;
   }
 }
}