.c-breadcrumbs {

    li {
        display: inline-block;
        font-size: 15px;
        color: var(--color-primary);

        &:not(:first-of-type)::before {
            content: "/";
            padding: 0 5px;
            color: #ccc;
        }

        a {
            font-size: 15px;
            color: var(--color-primary);
        }

        &.active {
            opacity: .5;
        }
        
    }

}